import React from 'react';
import {XML} from './components';
import './App.css'
const App = () => {

    return (
        <div className="App">
          <div  className="gradient__bg">
          <XML />
          </div>
        </div>
    )
}


export default App;
