import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import Box from '@mui/material/Box';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import {Component} from "react";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            Brouet {' '}
            {/*<Link color="inherit" href="#">*/}
            {/*    XML*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

class XML extends Component {

    state = {
        loading: false,
        // Initially, no file is selected
        selectedFile: null
    };

    Loader = () => (
        <div className="divLoader">
            <svg className="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
                <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
            </svg>
        </div>
    );

    GenerateButton = () => (
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 4 }}
        >
            Click here to generate excel file
        </Button>
    )

    // On file select (from the pop up)
    onFileChange = event => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
    };

    // On file upload (click the upload button)
    onFileUpload = () => {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file",
            this.state.selectedFile,
            this.state.selectedFile.name
        );

        // Details of the uploaded file
        console.log(this.state.selectedFile);

        // Request made to the backend api
        // Send formData object
        this.downloadTemplate(formData);
    };

    downloadTemplate(formData) {
        this.setState({loading : true});
        axios.post(process.env.REACT_APP_API_URL + "/api/v1/xml/parser", formData,
            {
                headers:
                    {
                        // 'Content-Disposition': "attachment; filename=amerijet_export_.xlsx",
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    },
                responseType: 'arraybuffer',
            }
        ).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var date = new Date().toDateString();
            console.log(response);
            link.setAttribute('download', 'amerijet_export_'+date+'.xlsx');
            document.body.appendChild(link);
            link.click();
            this.setState({loading : false});
        })
            .catch((error) => {
                console.log(error);
                this.setState({loading : false});
            });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();

        if(this.state.selectedFile.type !== 'text/xml') {
            return;
        }

        // Update the formData object
        formData.append(
            "file",
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        // Details of the uploaded file
        console.log(this.state.selectedFile);

        // Request made to the backend api
        // Send formData object
        this.downloadTemplate(formData);
    };
    // File content to be displayed after
    // file upload is complete

    fileData = () => {
        if (this.state.selectedFile) {

            return (

                <div>
                    <h2>File Details:</h2>
                    <p>File Name: {this.state.selectedFile.name}</p>
                    <p>File Type: {this.state.selectedFile.type}</p>
                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h4 style={{color : 'red'}}>Please select your XML File</h4>
                </div>
            );
        }
    };

    render() {
        return (

            <ThemeProvider theme={theme}>

                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    { (this.state.selectedFile != null && this.state.selectedFile.type !== 'text/xml') ? <Alert severity="error">Incorrect file uploaded, please upload an xml file!</Alert> : null}

                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 6, bgcolor: 'secondary.main' }}>
                            <InsertDriveFileIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            XML to EXCEL
                        </Typography>

                        <Box textAlign='center' component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                            {this.fileData()}

                                <Button
                                    color="secondary"
                                    variant="contained"
                                    component="label">
                                    Select XML File

                                    <input type="file" onChange={this.onFileChange} hidden />
                                </Button>
                            {this.state.loading ? <this.Loader /> : null}

                            {this.state.selectedFile && this.state.selectedFile.type === 'text/xml'  && !this.state.loading ? <this.GenerateButton /> : null}

                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            </ThemeProvider>
        );
    }
}

export default XML;
